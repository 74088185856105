export function CanvasIcon(): JSX.Element {
  return (
    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_2690_23118" fill="white">
        <rect x="5" y="14.5" width="21" height="21" rx="2" />
      </mask>
      <rect
        x="5"
        y="14.5"
        width="21"
        height="21"
        rx="2"
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        mask="url(#path-1-inside-1_2690_23118)"
      />
      <path
        d="M6.25 11.25V7.75C6.25 7.19772 6.69772 6.75 7.25 6.75H32.75C33.3023 6.75 33.75 7.19772 33.75 7.75V33.25C33.75 33.8023 33.3023 34.25 32.75 34.25H29.25"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
    </svg>
  )
}

const colors = [
  '#FFFFFF',
  '#000000',
  '#4FC3C4',
  '#1BAAA0',
  '#FC91AC',
  '#FC7E8A',
  '#FFA079',
  '#FFD276',
  '#669EED',
  '#72C1E2',
  '#79D2B9',
  '#AEA0E7',
  '#AC7FA1',
  '#D2C6AE',
  '#D9AE9D',
  '#5F4C40',
]

export default colors

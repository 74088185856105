export function RedoIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.5 24.75H13.0781C9.30623 24.75 6.24851 21.9218 6.24851 18.1499C6.24851 14.378 9.30623 11.3203 13.0781 11.3203H23.5"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.6796 15.3909L25.75 11.3608L21.6392 7.25"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

import * as React from 'react'
import { styled } from 'styles'
import * as SliderPrimitive from '@radix-ui/react-slider'

/* -------------------------------------------------- */
/*                  Slider component                  */
/* -------------------------------------------------- */

export interface SliderProps extends Omit<SliderPrimitive.SliderProps, 'value' | 'onChange'> {
  value?: number[]
  step: number
  min?: number
  max?: number
  defaultValue?: number[]
  onChange: (value: number[]) => void
  color?: string
  thumbColor?: string
  rangeColor?: string
}

export function PicSlider({
  value,
  onChange,
  step = 1,
  min = 0,
  max = 30,
  defaultValue,
  color = '#4C4C4C',
  thumbColor = color,
  rangeColor = color,
}: SliderProps) {
  return (
    <StyledSlider
      step={step}
      onValueChange={onChange}
      value={value}
      min={min}
      max={max}
      defaultValue={defaultValue}
    >
      <StyledTrack>
        <StyledRange css={{ backgroundColor: rangeColor }} />
      </StyledTrack>
      <StyledThumb css={{ backgroundColor: thumbColor }} />
    </StyledSlider>
  )
}

const StyledSlider = styled(SliderPrimitive.Root, {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  userSelect: 'none',
  touchAction: 'none',
  width: '100%',

  '&[data-orientation="horizontal"]': {
    height: 20,
  },

  '&[data-orientation="vertical"]': {
    flexDirection: 'column',
    width: 20,
    height: 100,
  },
})

const StyledTrack = styled(SliderPrimitive.Track, {
  backgroundColor: '#CCCCCC',
  position: 'relative',
  flexGrow: 1,
  borderRadius: '9999px',

  '&[data-orientation="horizontal"]': { height: 3 },
  '&[data-orientation="vertical"]': { width: 3 },
})

const StyledRange = styled(SliderPrimitive.Range, {
  position: 'absolute',
  backgroundColor: '#4FC3C4',
  borderRadius: '9999px',
  height: '100%',
})

const StyledThumb = styled(SliderPrimitive.Thumb, {
  all: 'unset',
  display: 'block',
  width: 16,
  height: 16,
  backgroundColor: '#4FC3C4',
  borderRadius: 10,
})

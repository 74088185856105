import { TldrawApp } from '@tldraw/tldraw'
import { RedoIcon } from 'components/icons/RedoIcon'
import { UndoIcon } from 'components/icons/UndoIcon'
import { FC, useEffect, useState } from 'react'
import { styled } from 'styles'

const TopPanel: FC<{ app: TldrawApp | undefined }> = ({ app }) => {
  const [isEditingSlot, setIsEditingSlot] = useState(false)

  const handleDoneEditing = () => {
    app?.toggleEditSlot()
  }

  useEffect(() => {
    const handleEditSlot = (isEditing) => () => setIsEditingSlot(isEditing)
    document.addEventListener('editSlot', handleEditSlot(true))
    document.addEventListener('editSlotDone', handleEditSlot(false))
    return () => {
      document.removeEventListener('editSlot', handleEditSlot(true))
      document.removeEventListener('editSlotDone', handleEditSlot(false))
    }
  }, [])

  return (
    <Panel>
      {isEditingSlot ? (
        <ToolButton onClick={handleDoneEditing}>Done</ToolButton>
      ) : (
        <>
          <ToolButton>
            <UndoIcon onClick={app?.undo} />
          </ToolButton>
          <ToolButton>
            <RedoIcon onClick={app?.redo} />
          </ToolButton>
        </>
      )}
      {/* <ZoomMenu /> */}
    </Panel>
  )
}

export default TopPanel

const Panel = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 40,
  margin: 'auto',
  padding: '14px 32px',
  position: 'absolute',
  height: 60,
  top: 0,
  // background: '#FAFAFA',
  background: 'var(--toppanel-bg)',
  borderRadius: '0px 0px 16px 16px',
  borderBottom: '2px solid #f3f3f3',
  borderLeft: '2px solid #f3f3f3',
  borderRight: '2px solid #f3f3f3',
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 10,
})

const ToolButton = styled('button', {
  width: 32,
  height: 32,
  padding: 0,
  outline: 'none',
  cursor: 'pointer',
  userSelect: 'none',
  pointerEvents: 'all',
  // background: 'none',
  border: 'none',
  borderRadius: '$2',
  margin: '0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: '$ui',
  color: 'var(--toppanel-icon-color)',
  background: 'var(--toppanel-bg)',
  boxSizing: 'border-box',
  [`&:hover:not(:disabled)`]: {
    backgroundColor: 'var(--toppanel-item-hover-bg)',
  },
  [`&:focus:not(:disabled)`]: {
    backgroundColor: 'var(--toppanel-item-hover-bg)',
  },
  variants: {
    text: {
      true: {
        minWidth: 64,
        fontSize: '$3',
      },
    },
  },
})

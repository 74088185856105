import * as React from 'react'
import { styled } from 'styles'
import { Cross2Icon } from '@radix-ui/react-icons'

/* -------------------------------------------------- */
/*                  StickerPanel component            */
/* -------------------------------------------------- */

const server_uri = 'https://pico-peko-staging.herokuapp.com/'
const stickersQueryStr = `
{                                                       
  category(key: "all-sticker-packs") {               
    key                                                       
    name                                                      
    bundles(bundle_type: MIX, first: 30, after: "") {              
      total_count                                             
      edges {                                                 
        node {                                                
          id                                                  
          type                                                
          count                                                                                            
          title                                                                                
          count                                                                                        
          thumbnail                                                                                      
          thumbnails
          items {                                             
            edges {                                           
               node {                                         
                   url                                        
                   thumbnail                                  
               }                                              
            }                                                 
          }                                                                            
        }                                                     
      }                                                       
      pageInfo {                                              
          endCursor                                           
          hasNextPage                                         
      }                                                       
    }                                                         
  }                                                           
}      
`
export interface StickerPanelProps {
  amount?: number
  onChooseSticker: (uri: string) => void
}

interface IPropsCategory {
  id?: string
  count: number
  thumbnail: string
  title: string
  type: string
  items: string[]
}

export function StickerPanel({ amount = 10, onChooseSticker }: StickerPanelProps) {
  const [packIndex, setPackIndex] = React.useState<number | null>(0)

  const [packs, setPacks] = React.useState<IPropsCategory[]>([])
  const [packResult, setPackResult] = React.useState(null)

  const [searchTerm, setSearchTerm] = React.useState<string>('')
  const [searchResult, setSearchResult] = React.useState(null)

  const isEmpty = (obj) => {
    return obj === null || obj?.length === 0
  }
  const handleSearch = async () => {
    if (isEmpty(searchTerm)) return
    fetch(
      `${server_uri}REST?url=https://content.piccollage.com/api/v2/stickers?query=${searchTerm}`
    )
      .then((res) => res.json())
      .then((data) => {
        setSearchResult({
          count: data?.result?.length,
          thumbnail: '',
          title: searchTerm,
          type: '',
          items: data?.result?.map((datum) => datum?.image_url),
        })
      })
  }
  const addStickerToCanvas = (p: string) => {
    // add sticker uri into canvas
    onChooseSticker(p)
  }
  const fetchContent = () => {
    fetch(`${server_uri}sticker/graphql`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: stickersQueryStr,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setPacks(
          res?.category?.bundles?.edges?.map((e): IPropsCategory => {
            return {
              count: e.node?.count,
              thumbnail: e.node?.thumbnail,
              title: e.node?.title,
              type: e.node?.type,
              items: e.node?.items?.edges?.map((item) => item?.node?.url),
            }
          })
        )
      })
      .catch((err) => {
        console.log(err)
      })
  }

  React.useEffect(() => {
    fetchContent()
  }, [])
  React.useEffect(() => {
    if (isEmpty(searchTerm)) {
      setSearchResult(null)
    }
  }, [searchTerm])

  return (
    <StickerPanelWrapper>
      {/* Search component */}
      <Field css={{ width: '100%' }}>
        <Flex>
          <InpurtWrapper>
            <Input
              type="text"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value)
              }}
              placeholder="search: sun, dog, beach..."
            ></Input>
            {!isEmpty(searchTerm) && (
              <CancelButton onClick={() => setSearchTerm('')}>
                <CrossIcon />
              </CancelButton>
            )}
          </InpurtWrapper>
          <Button onClick={handleSearch}>Search</Button>
        </Flex>
      </Field>

      {/* Header: packs */}
      {packs?.length === 0 || !isEmpty(searchTerm) ? (
        ''
      ) : (
        <Field>
          <StickerPacksPreview>
            {packs?.map((p, id) => (
              <StickerCell
                key={p.title}
                size={'medium'}
                css={{ backgroundImage: `url(${p.thumbnail})` }}
                onClick={() => {
                  setPackResult(p)
                  setPackIndex(id)
                }}
              />
            ))}
          </StickerPacksPreview>
        </Field>
      )}

      {/* body: results */}
      {(packResult !== null || searchResult !== null) && (
        <Field>
          <StickerPackContent>
            {(isEmpty(searchTerm) ? packResult : searchResult)?.items?.map((p, id) => (
              <StickerCell
                key={id}
                size={'large'}
                css={{ backgroundImage: `url(${p})` }}
                border={false}
                onClick={() => addStickerToCanvas(p)}
              />
            ))}
          </StickerPackContent>
        </Field>
      )}
    </StickerPanelWrapper>
  )
}

const StickerPanelWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  background: 'var(--panel-bg)',
})

const Field = styled('div', {
  padding: 4,
  overflow: 'scroll',

  [`&::-webkit-scrollbar`]: {
    display: 'none',
  },
  '-ms-overflow-style': 'none' /* IE and Edge */,
  'scrollbar-width': 'none' /* Firefox */,
})

const StickerPacksPreview = styled('div', {
  display: 'flex',
  gap: 8,
  padding: 8,
  width: 'fit-content',
  height: 'fit-content',
})

const StickerPackContent = styled('div', {
  padding: 8,
  display: 'grid',
  gridColumnGap: '16px',
  gridRowGap: '16px',
  gridTemplateColumns: 'repeat(3, 1fr)',
  justifyItems: 'center',
})
const StickerCell = styled('div', {
  border: '1px solid #c4c4c4',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'contain',

  cursor: 'pointer',
  variants: {
    border: {
      false: {
        border: 'none',
      },
    },
    size: {
      small: {
        width: 48,
        height: 48,
        borderRadius: 4,
      },
      medium: {
        width: 64,
        height: 64,
        borderRadius: 6,
      },
      large: {
        width: 80,
        height: 80,
        borderRadius: 6,
      },
    },
  },
})

const Input = styled('input', {
  all: 'unset',
  flex: '1 0 auto',
  borderRadius: 4,
  padding: '0 10px',
  fontSize: 14,
  lineHeight: 1,
  color: '#c4c4c4',
  border: `1px solid #848484`,
  height: 32,
})
const Flex = styled('div', {
  display: 'flex',
  gap: 4,
  padding: '4px',
  width: '100%',
})
const InpurtWrapper = styled('div', {
  position: 'relative',
  width: '100%',
  height: 'fit-content',
  display: 'flex',
})
const Button = styled('button', {
  all: 'unset',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 4,
  padding: '0 10px',
  fontSize: 14,
  lineHeight: 1,
  height: 'auto',
  width: '48px',

  backgroundColor: 'var(--green)',
  color: 'white',
  cursor: 'pointer',
})

const CancelButton = styled('div', {
  width: 24,
  height: 24,
  background: 'rgba(255, 255, 255, 0.1)',
  borderRadius: 4,
  position: 'absolute',
  top: 5,
  right: 5,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
})

const CrossIcon = styled(Cross2Icon, {
  width: 18,
  height: 18,
  color: '#848484',
})

import { AlignStyle, TextShape, TldrawApp } from '@tldraw/tldraw'
import { FC, useEffect, useState } from 'react'
import { ChromePicker, CirclePicker } from 'react-color'
import { styled } from 'styles'
import { Popover } from '../Popover'
import { SelectComponent } from '../Select'
import { PicSlider } from '../Slider'
import { TextStyleToggle } from '../TextStyleToggle'
import { getShadowColor, getStyle } from '../TextStyleToggle/textStyles'
import { ToggleGroupComponent } from '../ToggleGroup'
import { Section, SectionTitle } from 'components/UI/SettingPanel/Section'
// import WebFont from 'webfontloader'

const WebFont = typeof window === 'undefined' ? {} : require('webfontloader')

const applyFontStyle = ({ fontSetting }: { fontSetting: { family: string } }) => {
  if (fontSetting.family) {
    WebFont.load({
      google: {
        families: [fontSetting.family],
      },
    })
  }
}

export const TextSettings: FC<{ app: TldrawApp; shapeId: string }> = ({ app, shapeId }) => {
  const [shape, setShape] = useState<TextShape>()
  const [textData, setTextData] = useState({
    textSize: 28,
    textColor: '#000',
    alignment: AlignStyle.Start,
    font: null,
    shadowColor: null,
  })

  useEffect(() => {
    const handleLoadFont = (e: CustomEvent) =>
      e.detail && applyFontStyle({ fontSetting: { family: e.detail } })
    const handleSetFontData = (e: CustomEvent) => {
      if (e?.detail?.data) {
        const { textSize = 28, textColor = '#000', font, alignment, shadowColor } = e.detail.data
        setTextData({ textSize, textColor, font, alignment, shadowColor })
      }
    }
    document.addEventListener('loadfont', handleLoadFont)
    document.addEventListener('setfontdata', handleSetFontData)
    return () => {
      document.removeEventListener('loadfont', handleLoadFont)
      document.removeEventListener('setfontdata', handleSetFontData)
    }
  }, [setTextData])

  useEffect(() => {
    if (app) {
      setShape(shapeId ? (app.getShape(shapeId) as TextShape) : undefined)
    }
  }, [app, shapeId, setShape])

  if (!shapeId || !shape) return null

  return (
    <>
      <Section>
        <SectionTitle>Typeface</SectionTitle>
        <SelectComponent
          id="textFamilySelector"
          data={[
            {
              title: 'google',
              list: [
                { label: 'Caveat Brush', value: 'Caveat Brush' },
                { label: 'Architects Daughter', value: 'Architects Daughter' },
                { label: 'Krona One', value: 'Krona One' },
                { label: 'Pacifico', value: 'Pacifico' },
                { label: 'Gochi Hand', value: 'Gochi Hand' },
                { label: 'Rubik Puddles', value: 'Rubik Puddles' },
                { label: 'Gaegu', value: 'Gaegu' },
                { label: 'Fugaz One', value: 'Fugaz One' },
                { label: 'Neonderthaw', value: 'Neonderthaw' },
                { label: 'Reenie Beanie', value: 'Reenie Beanie' },
                { label: 'Bubblegum Sans', value: 'Bubblegum Sans' },
                { label: 'Tourney', value: 'Tourney' },
                { label: 'Questrial', value: 'Questrial' },
                { label: 'Nothing You Could Do', value: 'Nothing You Could Do' },
                { label: 'Pangolin', value: 'Pangolin' },
                { label: 'Cedarville Cursive', value: 'Cedarville Cursive' },
                { label: 'Luckiest Guy', value: 'Luckiest Guy' },
                { label: 'Fredericka the Great', value: 'Fredericka the Great' },
                { label: 'Redacted Script', value: 'Redacted Script' },
                { label: 'Italiana', value: 'Italiana' },
                { label: 'Special Elite', value: 'Special Elite' },
                { label: 'Neucha', value: 'Neucha' },
                { label: 'Rampart One', value: 'Rampart One' },
                { label: 'Cabin Sketch', value: 'Cabin Sketch' },
                { label: 'DM Serif Display', value: 'DM Serif Display' },
                { label: 'Lemon', value: 'Lemon' },
                { label: 'Impact', value: 'Impact' },
                { label: 'Vast Shadow', value: 'Vast Shadow' },
                { label: 'Abril Fatface', value: 'Abril Fatface' },
                { label: 'Press Start 2P', value: 'Press Start 2P' },
                { label: 'Monoton', value: 'Monoton' },
              ],
            },
          ]}
          value={textData.font}
          onChange={(value) => {
            applyFontStyle({
              fontSetting: {
                family: value,
              },
            })
            app?.styleText({ font: value })
            setTextData({
              ...textData,
              font: value,
            })
          }}
        />
      </Section>
      <Section>
        <SectionTitle>Text Size</SectionTitle>
        <PicSlider
          value={[textData.textSize]}
          min={8}
          max={192}
          step={1}
          color="#4C4C4C"
          onChange={(value) => {
            app?.styleText({ size: value[0] })
            setTextData({
              ...textData,
              textSize: value[0],
            })
            setShape((s) => ({
              ...s,
              textStyle: { ...s?.textStyle, size: value[0] },
            }))
          }}
        />
      </Section>
      <Section>
        <SectionTitle>Alignment</SectionTitle>
        <ToggleGroupComponent
          value={textData.alignment}
          onChange={(value) => {
            app?.styleText({ alignHorizontal: value })
            setTextData({
              ...textData,
              alignment: value,
            })
          }}
        />
      </Section>
      <Section>
        <SectionTitle>
          <Flex>
            Color
            <Popover
              trigger={
                <Swatch>
                  <SwatchColor css={{ background: textData.textColor }} />
                </Swatch>
              }
            >
              <CustomChromePicker
                color={textData.textColor}
                onChangeComplete={({ rgb }) => {
                  const color = `rgba(${Object.values(rgb).join(',')})`
                  app?.styleText({
                    color,
                    effect: {
                      shadowColor: getShadowColor(shape?.textStyle?.effect?.type, color),
                    },
                  })
                }}
                onChange={({ rgb }) => {
                  const color = `rgba(${Object.values(rgb).join(',')})`
                  setTextData({
                    ...textData,
                    textColor: color,
                  })
                  setShape((s) => ({
                    ...s,
                    textStyle: {
                      ...s?.textStyle,
                      color,
                      ...(s?.textStyle?.effect && {
                        effect: {
                          ...s?.textStyle.effect,
                          shadowColor: getShadowColor(s?.textStyle?.effect?.type, color),
                        },
                      }),
                    },
                  }))
                }}
              />
            </Popover>
          </Flex>
        </SectionTitle>
        <CirclePickerComponent
          circleSpacing={14}
          circleSize={24}
          colors={[
            '#f44336',
            '#e91e63',
            '#9c27b0',
            '#673ab7',
            '#3f51b5',
            '#2196f3',
            '#03a9f4',
            '#00bcd4',
            '#009688',
            '#4caf50',
            '#8bc34a',
            '#cddc39',
            '#ffeb3b',
            '#ffc107',
            '#ff9800',
          ]}
          onChange={({ rgb }) => {
            const color = `rgba(${Object.values(rgb).join(',')})`
            setTextData({
              ...textData,
              textColor: color,
            })
            setShape((s) => ({
              ...s,
              textStyle: {
                ...s?.textStyle,
                color,
                ...(s?.textStyle?.effect && {
                  effect: {
                    ...s?.textStyle.effect,
                    shadowColor: getShadowColor(s?.textStyle?.effect?.type, color),
                  },
                }),
              },
            }))
            app?.styleText({
              color,
              ...(shape?.textStyle?.effect && {
                effect: { shadowColor: getShadowColor(shape?.textStyle?.effect?.type, color) },
              }),
            })
          }}
        />
      </Section>
      <Section>
        <SectionTitle>Text Style</SectionTitle>
        <TextStyleToggle
          value={shape?.textStyle.effect}
          onChange={(type) => {
            const style = getStyle(app?.getShape(shapeId) ?? shape, type)
            app?.styleText(style)
            setShape((s) => ({
              ...s,
              textStyle: { ...s.textStyle, effect: style.effect },
            }))
          }}
          onAdjustStyle={(key, value) => {
            app?.styleText({ effect: { [key]: value } })
            setShape((s) => ({
              ...s,
              textStyle: {
                ...s.textStyle,
                effect: {
                  ...s.textStyle.effect,
                  [key]: value,
                },
              },
            }))
          }}
        />
      </Section>
    </>
  )
}

const Swatch = styled('div', {
  padding: '5px',
  background: '#fff',
  borderRadius: '1px',
  boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
  display: 'inline-block',
  cursor: 'pointer',
})

const SwatchColor = styled('div', {
  width: '36px',
  height: '14px',
  borderRadius: '2px',
})

const Flex = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const CirclePickerComponent = styled(CirclePicker, {
  width: '100% !important',
  margin: 0,
})

const CustomChromePicker = styled(ChromePicker, {
  '&.chrome-picker': {
    width: '192px !important',
    marginBottom: 32,
  },
})

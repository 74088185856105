import { TldrawApp } from '@tldraw/tldraw'
import { PANELS } from 'components/SideBar'
import { BackgroundSettings } from 'components/UI/SettingPanel/BackgroundSettings'
import { GridSettings } from 'components/UI/SettingPanel/GridSettings'
import { TextSettings } from 'components/UI/SettingPanel/TextSettings'
import { FC, useEffect, useState } from 'react'
import { styled } from 'styles'

export const SettingPanel: FC<{ app: TldrawApp; leftPanel: PANELS }> = ({ app, leftPanel }) => {
  const [textId, setTextId] = useState('')

  // const showPanel = !!(textId || [PANELS.BACKGROUND, PANELS.LAYOUT].includes(leftPanel))
  const showPanel = !!(textId || [PANELS.LAYOUT].includes(leftPanel))

  useEffect(() => {
    if (app) {
      const handleSelectText = (e: CustomEvent) => setTextId(e?.detail || '')
      document.addEventListener('selectText', handleSelectText)
      return () => {
        document.removeEventListener('selectText', handleSelectText)
      }
    }
  }, [app, setTextId])

  // useEffect(() => {
  //   const root = document.documentElement

  //   if (leftPanel === PANELS.BACKGROUND || leftPanel === PANELS.LAYOUT || !!textId) {
  //     root.style.setProperty('--canvas-padding-right', `304px`)
  //   } else {
  //     root.style.setProperty('--canvas-padding-right', `64px`)
  //   }
  // }, [showPanel, leftPanel])

  if (!showPanel) return null

  return (
    <Wrapper>
      <InnerWrapper>
        {!!textId && <TextSettings app={app} shapeId={textId} />}
        {leftPanel === PANELS.LAYOUT && <GridSettings app={app} />}
        {leftPanel === PANELS.BACKGROUND && <BackgroundSettings app={app} />}
      </InnerWrapper>
    </Wrapper>
  )
}

const Wrapper = styled('div', {
  width: 240,
  minWidth: 240,
  maxWidth: 240,
  height: 'calc(100vh - 80px)',
  overflowY: 'overlay',
  overflowX: 'hidden',
  background: 'white',
  borderLeft: '1px solid #ddd',
  padding: '32px 24px',
  marginBottom: 32,
  position: 'absolute',
  right: 0,
  zIndex: 1,
})

const InnerWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 32,
  minHeight: '100%',
})

import {
  EffectStyle,
  TDShapeType,
  TextEffectStyle,
  TextShape,
  TextShapeStyles,
} from '@tldraw/tldraw'

export const textStyles: (TextEffectStyle & { icon: Record<string, string> })[] = [
  {
    type: EffectStyle.NONE,
    icon: {
      color: '#4C4C4C',
      textShadow: '',
    },
  },
  {
    type: EffectStyle.SHADOW_S,
    direction: -45,
    distance: 18,
    blur: 2,
    shadowColor: 'shadowColor.20',
    icon: {
      color: '#4C4C4C',
      textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
    },
  },
  {
    type: EffectStyle.LIFT,
    direction: -45,
    distance: 88,
    blur: 5,
    shadowColor: 'shadowColor.30',
    icon: {
      color: '#B897D8',
      textShadow: '5px 6px 5px rgba(184, 151, 216, 0.3)',
    },
  },
  {
    type: EffectStyle.SHADOW_L,
    direction: -45,
    distance: 71,
    blur: 1,
    shadowColor: 'shadowColor.18',
    icon: {
      color: '#8A58F6',
      textShadow: '5px 4px 1px rgba(138, 88, 246, 0.18)',
    },
  },
  {
    type: EffectStyle.SHADOW_M,
    direction: -45,
    distance: 18,
    blur: 0,
    shadowColor: 'shadowColor.58',
    icon: {
      color: '#8A58F6',
      textShadow: '1px 1px 0px rgba(138, 88, 246, 0.58)',
    },
  },

  // TODO: Neon should have more than one shadow
  {
    type: EffectStyle.NEON,
    intensity: 50,
    shadowColor: 'shadowColor.100',
    icon: {
      color: '#F37BE0',
      textShadow: '0px 0px 8px #F37BE0',
    },
  },
]

const hexToRgba = (hex: string, defaultColor: string) => {
  if (!hex) return ''
  let color = hex
  const result = [0, 0, 0, 0]
  if (color.includes('shadowColor')) {
    color = defaultColor
    result[3] = (Number(hex.split('.')[1]) ?? 0) / 100
  }
  if (color.includes('rgb')) {
    color
      .match(/\d+\.?\d*/g)
      .slice(0, 3)
      .map(Number)
      .forEach((c, i) => (result[i] = c ?? 0))
  } else {
    const step = Math.floor((color.length - 1) / 3)
    Array.from([0, 1, 2]).forEach((i) => {
      const start = i * step + 1
      result[i] = parseInt(color.slice(start, start + step), 16)
    })
  }

  return `rgba(${result.join(',')})`
}

export const rgbaToHex = (rgb: string, defaultColor: string) => {
  if (!rgb) return ''
  let color = rgb
  const result = [0, 0, 0, 0]
  if (color.includes('shadowColor')) {
    color = defaultColor
    result[3] = Math.round((Number(rgb.split('.')[1]) ?? 0) / 100)
  }
  if (color.includes('#'))
    return color.length > 7
      ? color
      : color +
          `${Math.round(result[3] * 255)
            .toString(16)
            .padStart(2, '0')}`
  if (color.includes('rgb')) {
    color
      .match(/\d+\.?\d*/g)
      .map(Number)
      .forEach((c, i) => {
        result[i] = c ?? 0
        if (i === 3) {
          result[i] = Math.round(result[i] * 255)
        }
      })
  }

  return `#${result
    .slice(0, 4)
    .map((r) => r.toString(16).padStart(2, '0'))
    .join('')}`
}

export const getShadowColor = (type: EffectStyle = EffectStyle.NONE, defaultColor: string) => {
  const t = textStyles.find(({ type: tType }) => tType === type)
  return hexToRgba(t.shadowColor, defaultColor)
}

export const getStyle = (
  shape: TextShape,
  type: EffectStyle = shape.textStyle.effect?.type
): Partial<TextShapeStyles> => {
  const t = textStyles.find(({ type: tType }) => tType === type)
  if (t && shape.type === TDShapeType.Text) {
    switch (t.type) {
      case EffectStyle.SHADOW_S:
      case EffectStyle.SHADOW_M:
      case EffectStyle.SHADOW_L:
      case EffectStyle.LIFT:
      case EffectStyle.NEON: {
        return {
          effect: {
            ...t,
            shadowColor: hexToRgba(t.shadowColor, shape.textStyle.color),
          },
        }
      }
      case EffectStyle.NONE:
      default:
        return {
          effect: {
            ...t,
            shadowColor: 'transparent',
          },
        }
    }
  }
  return {}
}

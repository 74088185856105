import React, { useState, MouseEvent, useContext, useCallback } from 'react'
import { GifsResult, GiphyFetch, MediaType } from '@giphy/js-fetch-api'
import { IGif, IImage } from '@giphy/js-types'
import { SearchBar, SearchContext, SearchContextManager, Grid } from '@giphy/react-components'
import { styled } from 'styles'

const giphyApiKey = process.env.NEXT_PUBLIC_GIPHY_API_KEY
const giphy = new GiphyFetch(giphyApiKey)

type GifType = MediaType | 'emoji'

export interface GiphyPickerProps {
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void
  onSelect?: (selected: IImage) => void
}

const SearchComponent: React.FC<React.PropsWithChildren<{ type: GifType }>> = ({
  children,
  type,
}) => {
  return (
    <SearchContextManager apiKey={giphyApiKey}>
      <CustomSearchBar disabled={type === 'emoji'} />
      {children}
    </SearchContextManager>
  )
}

const GridDemo: React.FC<{
  type: GifType
  onClick: (gif: IGif | null, event: React.SyntheticEvent) => void
}> = ({ type, onClick }) => {
  const { searchKey } = useContext(SearchContext)

  const getTrending = (offset: number) =>
    type === 'emoji'
      ? giphy.emoji({ offset, limit: 10 })
      : giphy.trending({ offset, type, limit: 10 })
  const fetchGifs = useCallback(
    async (offset: number) => {
      switch (type) {
        case 'text': {
          const aniRes = await giphy.animate(searchKey, {
            limit: 3,
            offset: Math.ceil(offset / 10) * 3,
          })
          const textRes = await giphy.search(searchKey, {
            sort: 'relevant',
            limit: 7,
            offset: Math.ceil(offset / 10) * 7,
            type: 'text',
          })
          return {
            meta: textRes.meta,
            pagination: {
              count: aniRes.pagination.count + textRes.pagination.count,
              total_count: aniRes.pagination.total_count + textRes.pagination.total_count,
              offset: offset + aniRes.pagination.count + textRes.pagination.count,
            },
            data: [...aniRes.data, ...textRes.data],
          } as GifsResult
        }
        case 'emoji':
          return giphy.emoji({ offset, limit: 10 })
        default:
          return giphy.search(searchKey, {
            offset,
            sort: 'relevant',
            limit: 10,
            type: type as MediaType,
          })
      }
    },
    [type, searchKey]
  )

  return (
    <Grid
      onGifClick={onClick}
      fetchGifs={searchKey ? fetchGifs : getTrending}
      width={320}
      columns={2}
      gutter={16}
      noLink
      key={type + searchKey}
    />
  )
}

export const GiphyPanel: React.FC<{ onClick: (uri: string) => void }> = ({ onClick }) => {
  const [type, setType] = useState<GifType>('gifs')

  const handleChange = (type: GifType) => () => {
    setType(type)
  }
  const handleClick = (gif: IGif | null) => {
    // TODO: maybe differentiate GIF and sticker as ImageShape and StickerShape
    const isDynamic = (gif as IGif & { is_dynamic: boolean }).is_dynamic
    // TODO: find not too hacky way
    return gif && onClick(isDynamic ? gif.url : `https://i.giphy.com/media/${gif.id}/giphy.webp`)
  }

  return (
    <Container>
      <ButtonGroup>
        <CustomButton isActive={type === 'gifs'} onClick={handleChange('gifs')}>
          GIFs
        </CustomButton>
        <CustomButton isActive={type === 'stickers'} onClick={handleChange('stickers')}>
          Stickers
        </CustomButton>
        <CustomButton isActive={type === 'text'} onClick={handleChange('text')}>
          Text
        </CustomButton>
        <CustomButton isActive={type === 'emoji'} onClick={handleChange('emoji')}>
          Emoji
        </CustomButton>
      </ButtonGroup>
      <SearchComponent type={type}>
        <GridDemo type={type} onClick={handleClick} />
      </SearchComponent>
    </Container>
  )
}

const Container = styled('div', {
  padding: 16,
  '& .giphy-gif': {
    cursor: 'pointer',
    '&::after': {
      top: 0,
      content: ' ',
      height: '100%',
      position: 'absolute',
      background: 'rgba(0, 0, 0, 0.2)',
      transition: 'width 5s, height 5s',
    },
    '&:hover': {
      '&::after': {
        width: '100%',
      },
    },
  },
})
const CustomSearchBar = styled(SearchBar, {
  marginBlock: 16,
  variants: {
    disabled: {
      true: {
        pointerEvents: 'none',
        cursor: 'not-allowed',
        opacity: 0.6,
        '& input, & div': {
          cursor: 'not-allowed',
        },
      },
    },
  },
})
const ButtonGroup = styled('div', {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: 16,
})
const CustomButton = styled('button', {
  alignItems: 'center',
  padding: '4px 12px',
  textAlign: 'center',
  border: 'solid 1px #FFFFFF',
  borderRadius: 14,
  backgroundColor: 'transparent',
  color: 'white',
  fontFamily: '"Montserrat", -apple-system, BlinkMacSystemFont',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 16,
  cursor: 'pointer',
  userSelect: 'none',
  pointerEvents: 'all',
  '&:disabled': {
    cursor: 'not-allowed',
    backgroundColor: '#3F9C9C',
  },
  [`&:hover:not(:disabled)`]: {
    backgroundColor: '#47AFB0',
    borderColor: '#47AFB0',
  },
  [`&:focus:not(:disabled)`]: {
    backgroundColor: '#47AFB0',
    borderColor: '#47AFB0',
  },
  variants: {
    isActive: {
      true: {
        backgroundColor: '#4FC3C4',
        borderColor: '#4FC3C4',
      },
    },
  },
})

export function UndoIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.49857 24.75H18.9205C22.6923 24.75 25.7501 21.9218 25.7501 18.1499C25.7501 14.378 22.6923 11.3203 18.9205 11.3203H8.49857"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.319 15.3909L6.24857 11.3608L10.3594 7.25"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

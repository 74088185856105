import { styled } from 'styles'

export const Section = styled('div', {
  minHeight: 64,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
})

export const SectionTitle = styled('div', {
  width: '100%',
  height: 24,
  fontWeight: '600',
  fontSize: 16,
  lineHeight: '20px',
  color: '#4C4C4C',
})

export const Spacer = styled('div', {
  flexGrow: 1,
})

import React from 'react'
import { styled } from 'styles'
import { PicSlider } from '../Slider'
import { EffectStyle, TextEffectStyle } from '@tldraw/tldraw'
import { ChromePicker } from 'react-color'
import { EditableInput } from 'react-color/lib/components/common'
import { rgbaToHex, textStyles } from 'components/UI/TextStyleToggle/textStyles'
import { Popover } from 'components/UI/Popover'

export const StyleAdjuster: React.FC<{
  effect?: TextEffectStyle
  onChange: (key: string, value: number | string) => void
}> = ({ effect, onChange }) => {
  const [values, setValues] = React.useState(effect)
  const [type, setType] = React.useState(
    textStyles.find((t) => t.type === (effect.type ?? EffectStyle.NONE))
  )

  const handleChange = (key: string) => (value: (number | string)[]) => {
    onChange(key, value[0])
    setValues((v) => ({ ...v, [key]: value[0] }))
  }

  React.useEffect(() => {
    setValues(effect)
    setType(textStyles.find((t) => t.type === (effect.type ?? EffectStyle.NONE)))
  }, [effect, setValues, setType])

  return (
    <Container>
      {'shadowColor' in type && (
        <Fieldset key="shadowColor">
          <Label htmlFor="shadowColor" css={{ flexBasis: '100%' }}>
            Shadow Color
          </Label>
          <Popover trigger={<Swatch css={{ background: values.shadowColor }} />}>
            <CustomChromePicker
              color={values.shadowColor}
              onChangeComplete={({ rgb }) =>
                handleChange('shadowColor')([`rgba(${Object.values(rgb).join(',')})`])
              }
              onChange={({ rgb }) =>
                setValues((v) => ({ ...v, shadowColor: `rgba(${Object.values(rgb).join(',')})` }))
              }
            />
          </Popover>
          <ColorInputWrapper>
            <EditableInput
              id="shadowColor"
              value={rgbaToHex(values.shadowColor, values.shadowColor)}
              onChange={(v) => handleChange('shadowColor')([v])}
            />
          </ColorInputWrapper>
        </Fieldset>
      )}
      {['blur', 'direction', 'distance', 'intensity'].map(
        (key) =>
          key in type && (
            <Fieldset key={key}>
              <Label htmlFor={key}>
                {key[0].toUpperCase()}
                {key.slice(1)}
              </Label>
              <Input
                id={key}
                type="number"
                step={1}
                value={[values[key]]}
                min={key === 'direction' ? -180 : 0}
                max={key === 'direction' ? 180 : 100}
                onChange={(e) => handleChange(key)([Number(e.target.value)])}
              />
              <PicSlider
                value={[values[key]]}
                min={key === 'direction' ? -180 : 0}
                max={key === 'direction' ? 180 : 100}
                step={1}
                color="#4C4C4C"
                onChange={handleChange(key)}
              />
            </Fieldset>
          )
      )}
    </Container>
  )
}

export default StyleAdjuster

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 32,
  padding: '16px 40px',
  borderRadius: 20,
  background: '#fff',
  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.15)',
})

const Fieldset = styled('fieldset', {
  all: 'unset',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: 192,
  rowGap: 12,
})

const Label = styled('label', {
  fontSize: 16,
  color: '#4C4C4C',
  fontWeight: 600,
})

const Input = styled('input', {
  all: 'unset',
  width: 40,
  height: 24,
  lineHeight: '22px',
  textAlign: 'center',
  borderRadius: 46,
  border: '1px solid #4C4C4C',
  fontSize: 16,

  '&:focus': { boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.1)' },

  /* remove number arrows */
  '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    appearance: 'none',
    margin: 0,
  },
})

const Swatch = styled('div', {
  cursor: 'pointer',
  width: 34,
  height: 34,
  border: 'solid 1px #4C4C4C',
  borderRadius: 12,
})

const CustomChromePicker = styled(ChromePicker, {
  '&.chrome-picker': {
    width: '192px !important',
    marginBottom: 32,
  },
})

const ColorInputWrapper = styled('div', {
  '& input': {
    all: 'unset',
    width: 104,
    height: 24,
    lineHeight: '22px',
    textAlign: 'center',
    borderRadius: 46,
    border: '1px solid #4C4C4C',
    fontSize: 16,

    '&:focus': { boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.1)' },
  },
  '& label': {
    display: 'none',
  },
})

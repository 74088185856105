export function GridSettingGapIcon(): JSX.Element {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.84874 6H25.1513C25.62 6 26 6.37999 26 6.84874V25.1513C26 25.62 25.62 26 25.1513 26H6.84874C6.37999 26 6 25.62 6 25.1513V6.84874C6 6.37999 6.37999 6 6.84874 6Z"
        stroke="#B3B3B3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 9L16 23"
        stroke="#4C4C4C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 16H23"
        stroke="#4C4C4C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

import { TldrawApp } from '@tldraw/tldraw'
import { FC, useEffect, useState } from 'react'
import { PicSlider } from 'components/UI/Slider'
import { Section, SectionTitle, Spacer } from 'components/UI/SettingPanel/Section'

export const BackgroundSettings: FC<{ app: TldrawApp }> = ({ app }) => {
  const getBackgroundSize = () => app?.background?.backgroundSize
  const [currentSize, setCurrentSize] = useState(getBackgroundSize()?.[0])

  useEffect(() => {
    if (app) {
      const handleChangeBackground = () => setCurrentSize(getBackgroundSize()?.[0])
      document.addEventListener('setPageBackground', handleChangeBackground)
      return () => {
        document.removeEventListener('setPageBackground', handleChangeBackground)
      }
    }
  }, [app, setCurrentSize, getBackgroundSize])

  return (
    <>
      <Spacer />
      <Section>
        <SectionTitle>Background Tile Size</SectionTitle>
        <PicSlider
          step={1}
          min={1}
          max={100}
          value={[currentSize]}
          disabled={!currentSize}
          onChange={(e) => {
            app?.setPageBackgroundSize(e[0])
            setCurrentSize(getBackgroundSize()?.[0])
          }}
        />
      </Section>
      <Spacer />
    </>
  )
}

import { TldrawApp } from '@tldraw/tldraw'
import * as React from 'react'
import { styled } from 'styles'
import colors from 'utils/colors'

/* -------------------------------------------------- */
/*                  BackgroundPanel component            */
/* -------------------------------------------------- */

const server_uri = 'https://pico-peko-staging.herokuapp.com/'
const backgroundQueryStr = `{                                                       
  category(key: "all-background-packs") {               
    key                                                       
    name                                                      
    bundles(bundle_type: MIX, first: 30, after: "") {              
      total_count                                             
      edges {                                                 
        node {                                                
          id
          type
          count
          title
          count
          thumbnail
          thumbnails
          items {
            edges {
               node {
                   url
                   thumbnail
               }                                              
            }
          }
        }
      }
      pageInfo {
          endCursor
          hasNextPage
      }
    }
  }
}`
export interface BackgroundPanelProps {
  amount?: number
  app?: TldrawApp
  onChooseBg: (uri: string) => void
}

interface IPropsCategory {
  id?: string
  count: number
  thumbnail: string
  title: string
  type: string
  items: string[]
}

export function BackgroundPanel({ amount = 10, app, onChooseBg }: BackgroundPanelProps) {
  const [selected, setSelected] = React.useState<TldrawApp['background']>()
  const [packs, setPacks] = React.useState<IPropsCategory[]>([])
  const [packIndex, setPackIndex] = React.useState<number | null>(null)

  const setCanvasBg = async (p: string) => {
    // add bg uri to canvas bg
    await onChooseBg(p)
    setSelected(app.background)
  }

  React.useEffect(() => {
    if (app) {
      setSelected(app.background)
    }
  }, [app])

  React.useEffect(() => {
    const fetchContent = () => {
      fetch(`${server_uri}sticker/graphql`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: backgroundQueryStr,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setPacks(
            res?.category?.bundles?.edges?.map((e): IPropsCategory => {
              return {
                count: e.node?.count,
                thumbnail: e.node?.thumbnail,
                title: e.node?.title,
                type: e.node?.type,
                items: e.node?.items?.edges?.map((item) => item?.node?.url),
              }
            })
          )
        })
        .catch((err) => {
          console.log(err)
        })
    }
    fetchContent()
  }, [])

  return (
    <BackgroundPanelWrapper>
      {/* Header: packs */}
      <span>Solid Color</span>
      <BackgroundWrapper>
        {colors.map((c) => (
          <CustomColorElement
            key={c}
            css={{ background: c }}
            selected={!selected?.assetId && c === selected?.backgroundColor}
            onClick={() => setCanvasBg(c)}
          />
        ))}
      </BackgroundWrapper>
      {/* 
      <span>Patterns</span>
      {packs.length === 0 ? (
        'loading...'
      ) : (
        <Field>
          <BackgroundPacksPreview>
            {packs.map((p, id) => (
              <BackgroundCell
                key={p.title}
                size={'medium'}
                css={{ backgroundImage: `url(${p.thumbnail})` }}
                onClick={() => setPackIndex(id)}
              />
            ))}
          </BackgroundPacksPreview>
        </Field>
      )}

      // body: results
      {packIndex !== null && (
        <Field>
          <BackgroundPackContent>
            {packs[packIndex]?.items.map((p, id) => (
              <BackgroundCell
                key={id}
                size={'large'}
                border={false}
                css={{ backgroundImage: `url(${p})` }}
                selected={selected?.assetId === p}
                onClick={() => setCanvasBg(p)}
              />
            ))}
          </BackgroundPackContent>
        </Field>
      )} */}
    </BackgroundPanelWrapper>
  )
}

const BackgroundPanelWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
  background: 'var(--panel-bg)',
  color: '#D8D8D8',
  padding: 16,
  '& span': {
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '16px',
  },
})

const BackgroundWrapper = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  flexFlow: 'row wrap',
  gap: '24px 18px',
  padding: '16px 0px',
  width: '100%',
})

const CustomColorElement = styled('div', {
  width: 55,
  height: 55,
  boxSizing: 'border-box',
  borderRadius: 20,
  cursor: 'pointer',
  variants: {
    selected: {
      true: {
        border: 'solid 3px #4FC3C4',
      },
    },
  },
})

const Field = styled('div', {
  // padding: 4,
  overflow: 'scroll',

  [`&::-webkit-scrollbar`]: {
    display: 'none',
  },
  '-ms-overflow-style': 'none' /* IE and Edge */,
  'scrollbar-width': 'none' /* Firefox */,
})

const BackgroundPacksPreview = styled('div', {
  display: 'flex',
  gap: 8,
  paddingBlock: 8,
  width: 'fit-content',
  height: 'fit-content',
})

const BackgroundPackContent = styled('div', {
  paddingBlock: 8,
  display: 'grid',
  gridColumnGap: '16px',
  gridRowGap: '16px',
  gridTemplateColumns: 'repeat(3, 1fr)',
  justifyItems: 'center',
})

const BackgroundCell = styled('div', {
  border: '1px solid #c4c4c4',
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  cursor: 'pointer',
  variants: {
    border: {
      false: {
        border: 'none',
      },
    },
    size: {
      small: {
        width: 48,
        height: 48,
        borderRadius: 4,
      },
      medium: {
        width: 64,
        height: 64,
        borderRadius: 6,
      },
      large: {
        width: 80,
        height: 80,
        borderRadius: 6,
      },
    },
    selected: {
      true: {
        border: 'solid 3px #4FC3C4',
      },
    },
  },
})

export function ImageIcon(): JSX.Element {
  return (
    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.01508 6.5H31.9849C33.0978 6.5 34 7.40218 34 8.51508V32.4849C34 33.5978 33.0978 34.5 31.9849 34.5H8.01508C6.90218 34.5 6 33.5978 6 32.4849V8.51508C6 7.40218 6.90218 6.5 8.01508 6.5Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5842 18.4163C16.195 18.416 17.5007 17.11 17.5004 15.4992C17.5002 13.8884 16.1942 12.5827 14.5833 12.5829C12.9725 12.5832 11.6668 13.8892 11.6671 15.5C11.6673 17.1109 12.9733 18.4165 14.5842 18.4163Z"
        fill="currentColor"
      />
      <path
        d="M14 24.7592C10.8 24.7592 7.77777 23.0308 6.66666 22.1666V31.8333C6.66666 32.9379 7.56208 33.8333 8.66665 33.8333H31.3333C32.4379 33.8333 33.3333 32.9379 33.3333 31.8333V24.7592C32 24.1111 28.6667 22.1666 25.3333 22.1666C21.2781 22.1666 18 24.7592 14 24.7592Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  )
}

import { SlotShape, TDShapeType, TldrawApp } from '@tldraw/tldraw'
import { FC, useState } from 'react'
import { styled } from 'styles'
import { GridSettingGapIcon } from 'components/icons/GridSettingGapIcon'
import { GridSettingPaddingIcon } from 'components/icons/GridSettingPaddingIcon'
import { GridSettingRadiusIcon } from 'components/icons/GridSettingRadiusIcon'
import { PicSlider } from 'components/UI/Slider'
import { Section, SectionTitle, Spacer } from 'components/UI/SettingPanel/Section'

export const GridSettings: FC<{ app: TldrawApp }> = ({ app }) => {
  const [currentPadding, setCurrentPadding] = useState(app?.getPage()?.padding ?? 8)
  const getSlots = () => app?.getShapes().filter((s) => s.type === TDShapeType.Slot) as SlotShape[]

  const [currentSlotGap, setCurrentSlotGap] = useState(getSlots()?.map((s) => s.margin)?.[0] ?? 8)
  const [currentSlotRadius, setCurrentSlotRadius] = useState(
    getSlots()?.map((s) => s.borderRadius)?.[0] ?? 2
  )

  return (
    <>
      <Spacer />
      <Section>
        <SectionTitleWithIcon>
          <IconWrapper>
            <GridSettingPaddingIcon />
          </IconWrapper>
          Outer Padding
        </SectionTitleWithIcon>
        <PicSlider
          step={1}
          value={[currentPadding]}
          onChange={(e) => {
            app?.setPagePadding(e[0])
            setCurrentPadding(app?.getPage()?.padding)
          }}
        />
      </Section>
      <Section>
        <SectionTitleWithIcon>
          <IconWrapper>
            <GridSettingGapIcon />
          </IconWrapper>
          Inner Padding
        </SectionTitleWithIcon>
        <PicSlider
          step={1}
          value={[currentSlotGap]}
          onChange={(e) => {
            app?.setSlotsInnerPadding(e[0])
            setCurrentSlotGap(getSlots()?.map((s) => s.margin)?.[0])
          }}
        />
      </Section>
      <Section>
        <SectionTitleWithIcon>
          <IconWrapper>
            <GridSettingRadiusIcon />
          </IconWrapper>
          Rounded Corner
        </SectionTitleWithIcon>
        <PicSlider
          step={1}
          value={[currentSlotRadius]}
          onChange={(e) => {
            app?.setSlotsBorderRadius(e[0])
            setCurrentSlotRadius(getSlots()?.map((s) => s.borderRadius)?.[0])
          }}
        />
      </Section>
      <Spacer />
    </>
  )
}

const IconWrapper = styled('div', {
  width: '32px',
  height: '32px',
})

const SectionTitleWithIcon = styled(SectionTitle, {
  display: 'flex',
  alignItems: 'center',
  gap: 8,
})

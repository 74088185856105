export function BackgroundIcon(): JSX.Element {
  return (
    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_2537_30267"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="5"
        y="5"
        width="30"
        height="31"
      >
        <path
          d="M33.1132 5.5H6.88679C5.84475 5.5 5 6.34475 5 7.38679V33.6132C5 34.6553 5.84475 35.5 6.88679 35.5H33.1132C34.1553 35.5 35 34.6553 35 33.6132V7.38679C35 6.34475 34.1553 5.5 33.1132 5.5Z"
          fill="#C4C4C4"
        />
      </mask>
      <g mask="url(#mask0_2537_30267)">
        <path d="M6 6.5L34 34.5" stroke="currentColor" strokeWidth="2.5" strokeLinecap="square" />
        <path d="M13 13.5L6 20.5" stroke="currentColor" strokeWidth="2.5" strokeLinecap="square" />
        <path d="M17.5 18L6 29.5" stroke="currentColor" strokeWidth="2.5" strokeLinecap="square" />
        <path d="M22.5 23L11 34.5" stroke="currentColor" strokeWidth="2.5" strokeLinecap="square" />
        <path d="M27 27.5L19.5 35" stroke="currentColor" strokeWidth="2.5" strokeLinecap="square" />
        <path
          d="M14.5 7L34 26.5M22 6.5L34 18.5"
          stroke="currentColor"
          strokeWidth="2.5"
          strokeLinecap="square"
        />
        <path
          d="M6.88679 6.75H33.1132C33.4649 6.75 33.75 7.0351 33.75 7.38679V33.6132C33.75 33.9649 33.4649 34.25 33.1132 34.25H6.88679C6.5351 34.25 6.25 33.9649 6.25 33.6132V7.38679C6.25 7.0351 6.5351 6.75 6.88679 6.75Z"
          stroke="currentColor"
          strokeWidth="2.5"
        />
      </g>
    </svg>
  )
}

import * as React from 'react'
import { styled } from 'styles'
import { createClient, PhotosWithTotalResults } from 'pexels'
import { Cross2Icon } from '@radix-ui/react-icons'

const client = createClient('563492ad6f91700001000001dd635d8d112b49868e3ed2c19771af05')
const COLUMN_COUNT = 3
/* -------------------------------------------------- */
/*                  ImagePanel component            */
/* -------------------------------------------------- */

export interface ImagePanelProps {
  onClickUpload: () => void
  onAddImage: (uri: string) => void
}

export function ImagePanel({ onClickUpload, onAddImage }: ImagePanelProps) {
  const [searchTerm, setSearchTerm] = React.useState<string>('')
  const [searchResult, setSearchResult] = React.useState([])

  const addPhotoToCanvas = (uri) => {
    onAddImage(uri)
  }
  const isEmpty = (obj) => {
    return obj === null || obj?.length === 0
  }
  const onSearch = () => {
    fetch(`https://api.pexels.com/v1/search?query=${searchTerm}&per_page=50`, {
      headers: {
        Authorization: '563492ad6f91700001000001dd635d8d112b49868e3ed2c19771af05',
      },
    })
      .then((resp) => {
        return resp.json()
      })
      .then((data) => {
        setSearchResult(data.photos)
      })
  }
  React.useEffect(() => {
    fetch(`https://api.pexels.com/v1/curated?per_page=50`, {
      headers: {
        Authorization: '563492ad6f91700001000001dd635d8d112b49868e3ed2c19771af05',
      },
    })
      .then((resp) => {
        return resp.json()
      })
      .then((data) => {
        setSearchResult(data.photos)
      })
  }, [])

  return (
    <StickerPanelWrapper>
      {/* update */}
      <Field>
        <ActionButton onClick={() => onClickUpload()}>Upload</ActionButton>
      </Field>

      {/* Search */}
      <br />
      <br />
      <span>Web search</span>
      <Field css={{ width: '100%' }}>
        <Flex>
          <InpurtWrapper>
            <Input
              type="text"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value)
              }}
              placeholder="search: sun, dog, beach..."
            ></Input>
            {!isEmpty(searchTerm) && (
              <CancelButton onClick={() => setSearchTerm('')}>
                <CrossIcon />
              </CancelButton>
            )}
          </InpurtWrapper>
          <Button onClick={onSearch}>Search</Button>
        </Flex>
      </Field>

      {/* Choose third party */}
      <Field>
        <Gallery>
          {[...new Array(COLUMN_COUNT)]?.map((column, cId) => (
            <Column key={cId}>
              {searchResult
                ?.filter((photo, pId) => pId % COLUMN_COUNT === cId)
                ?.map((photo) => (
                  <Cell
                    key={photo.id}
                    src={`${photo?.src?.small}`}
                    onClick={() => addPhotoToCanvas(photo?.src?.large)}
                  />
                ))}
            </Column>
          ))}
        </Gallery>
      </Field>
    </StickerPanelWrapper>
  )
}

const StickerPanelWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  background: 'var(--panel-bg)',
  padding: 16,
  color: '#D8D8D8',
  '& span': {
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '16px',
  },
})

const Field = styled('div', {
  overflow: 'scroll',

  [`&::-webkit-scrollbar`]: {
    display: 'none',
  },
  '-ms-overflow-style': 'none' /* IE and Edge */,
  'scrollbar-width': 'none' /* Firefox */,
})

const Input = styled('input', {
  all: 'unset',
  flex: '1 0 auto',
  borderRadius: 4,
  padding: '0 10px',
  fontSize: 14,
  lineHeight: 1,
  color: '#c4c4c4',
  border: `1px solid #848484`,
  height: 32,
})
const Flex = styled('div', {
  display: 'flex',
  gap: 4,
  padding: '4px',
  width: '100%',
})
const InpurtWrapper = styled('div', {
  position: 'relative',
  width: '100%',
  height: 'fit-content',
  display: 'flex',
})
const Button = styled('button', {
  all: 'unset',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 4,
  padding: '0 10px',
  fontSize: 14,
  lineHeight: 1,
  height: 'auto',
  width: '48px',

  backgroundColor: 'var(--green)',
  color: 'white',
  cursor: 'pointer',
})

const CancelButton = styled('div', {
  width: 24,
  height: 24,
  background: 'rgba(255, 255, 255, 0.1)',
  borderRadius: 4,
  position: 'absolute',
  top: 5,
  right: 5,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
})

const CrossIcon = styled(Cross2Icon, {
  width: 18,
  height: 18,
  color: '#848484',
})

const Gallery = styled('div', {
  // background: 'rgba(204, 152, 152, 0.2)',
  width: '100%',
  display: 'flex',
  gap: '8px',
  padding: 4,
})

const Column = styled('div', {
  // background: 'rgba(145, 141, 192, 0.2)',
  gap: '8px',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
})

const Cell = styled('img', {
  width: '100%',
  height: 'auto',
  borderRadius: 8,
  cursor: 'pointer',
})

const ActionButton = styled('button', {
  alignItems: 'center',
  gap: 6,
  padding: '12px 16px',
  width: 'calc(100%)',
  backgroundColor: '#4FC3C4',
  textAlign: 'center',
  border: 'none',
  borderRadius: 12,
  color: 'white',
  fontFamily: '"Montserrat", -apple-system, BlinkMacSystemFont',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 16,
  cursor: 'pointer',
  userSelect: 'none',
  pointerEvents: 'all',
  '&:disabled': {
    cursor: 'not-allowed',
    backgroundColor: '#3F9C9C',
  },
  [`&:hover:not(:disabled)`]: {
    backgroundColor: '#47AFB0',
  },
  [`&:focus:not(:disabled)`]: {
    backgroundColor: '#47AFB0',
  },
})

import React from 'react'
import { styled } from 'styles'
import { TextAlignLeftIcon, TextAlignCenterIcon, TextAlignRightIcon } from '@radix-ui/react-icons'
import * as ToggleGroupPrimitive from '@radix-ui/react-toggle-group'

const StyledToggleGroup = styled(ToggleGroupPrimitive.Root, {
  display: 'inline-flex',
  backgroundColor: '#fff',
  borderRadius: 4,
  width: 'fit-content',
  gap: 4,
})

const StyledItem = styled(ToggleGroupPrimitive.Item, {
  all: 'unset',
  backgroundColor: '#F0F0F0',
  color: '#4c4c4c',
  height: 36,
  width: 36,
  display: 'flex',
  fontSize: 24,
  lineHeight: 1,
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: 1,
  borderRadius: 4,
  '&:hover': { backgroundColor: '#f3f3f3' },
  '&[data-state=on]': { color: '#4FC3C4' },

  '& > svg': {
    width: 24,
    height: 24,
  },
})

// Exports
export const ToggleGroup = StyledToggleGroup
export const ToggleGroupItem = StyledItem

export function ToggleGroupComponent({ value, onChange }) {
  return (
    <ToggleGroup type="single" value={value} aria-label="Text alignment" onValueChange={onChange}>
      <ToggleGroupItem value="start" aria-label="Left aligned">
        <TextAlignLeftIcon fontSize={18} />
      </ToggleGroupItem>
      <ToggleGroupItem value="middle" aria-label="Center aligned">
        <TextAlignCenterIcon />
      </ToggleGroupItem>
      <ToggleGroupItem value="end" aria-label="Right aligned">
        <TextAlignRightIcon />
      </ToggleGroupItem>
    </ToggleGroup>
  )
}

export default ToggleGroupComponent

import React from 'react'
import { styled } from 'styles'
import * as ToggleGroupPrimitive from '@radix-ui/react-toggle-group'
import { textStyles } from './textStyles'
import { EffectStyle, TextEffectStyle } from '@tldraw/tldraw'
import { Popover } from '../Popover'
import StyleAdjuster from 'components/UI/TextStyleToggle/StyleAdjuster'

export const TextStyleToggle: React.FC<{
  value?: TextEffectStyle
  onChange: (type: EffectStyle) => void
  onAdjustStyle: (key: string, value: number | string) => void
}> = ({ value, onChange, onAdjustStyle }) => {
  return (
    <Container>
      <StyledToggleGroup
        type="single"
        defaultValue={value?.type ?? EffectStyle.NONE}
        aria-label="Text style"
        onValueChange={(v) => v && onChange(v as EffectStyle)} // TODO: use value instead of defaultValue and disable toggle
      >
        {textStyles.map(({ type, icon }) => (
          <StyledItem
            key={type}
            value={type}
            aria-label={`Text style: ${type[0].toUpperCase()}${type.slice(1)}`}
          >
            <span style={{ ...icon }}>Ag</span>
          </StyledItem>
        ))}
      </StyledToggleGroup>
      {!!value && value?.type !== EffectStyle.NONE && (
        <Popover
          trigger={<Button>Adjust Style</Button>}
          side="top"
          alignOffset={-40}
          css={{ boxShadow: 'none' }}
        >
          <StyleAdjuster
            effect={value || textStyles.find((t) => t.type === value?.type)}
            onChange={onAdjustStyle}
          />
        </Popover>
      )}
    </Container>
  )
}

export default TextStyleToggle

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 24,
  marginBottom: 32,
})

const StyledToggleGroup = styled(ToggleGroupPrimitive.Root, {
  display: 'flex',
  flexWrap: 'wrap',
  backgroundColor: '#fff',
  width: '100%',
  gap: 6,
})

const StyledItem = styled(ToggleGroupPrimitive.Item, {
  all: 'unset',
  backgroundColor: '#F0F0F0',
  color: '#4C4C4C',
  height: 40,
  width: 56,
  display: 'flex',
  flexBasis: 56,
  fontSize: 24,
  lineHeight: 1,
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: 1,
  border: 'solid 1px #4C4C4C',
  borderRadius: 10,
  position: 'relative',
  '&:hover': { backgroundColor: '#D9D9D9' },
  '&[data-state=on]': {
    borderColor: '#4FC3C4',
    '&::after': {
      content: '',
      borderRadius: 10,
      border: 'solid 3px #4FC3C4',
      height: 'calc(100% - 3px)',
      width: 'calc(100% - 3px)',
      position: 'absolute',
    },
  },
})

const Button = styled('button', {
  all: 'unset',
  display: 'block',
  boxSizing: 'border-box',
  width: 128,
  minHeight: 30,
  background: '#4C4C4C',
  borderRadius: 46,
  cursor: 'pointer',
  userSelect: 'none',
  pointerEvents: 'all',
  fontWeight: 600,
  fontSize: 16,
  lineHeight: '20px',
  color: '#FAFAFA',
  textAlign: 'center',
})

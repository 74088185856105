import { AlignStyle, FontStyle, TDExport, TextPreset, TldrawApp } from '@tldraw/tldraw'
import AppBar from 'components/AppBar'
import type { GetServerSideProps } from 'next'
import { getSession } from 'next-auth/react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { Leva, useControls } from 'leva'
import { FC, useMemo, useState, useCallback, useEffect } from 'react'
// import WebFont from 'webfontloader'

declare global {
  interface Window {
    app?: TldrawApp
  }
}

const Editor = dynamic(() => import('components/Editor'), { ssr: false }) as any
const ACTION = 'download' as 'download' | 'open'
const WebFont = typeof window === 'undefined' ? {} : require('webfontloader')

const applyFontStyle = ({ fontSetting }: { fontSetting: { family: string } }) => {
  if (fontSetting.family) {
    WebFont.load({
      google: {
        families: [fontSetting.family],
      },
    })
  }
}

interface PageProps {
  isUser: boolean
  isSponsor: boolean
}

const Home: FC<PageProps> = () => {
  const { query } = useRouter()
  const isExportMode = useMemo(() => 'exportMode' in query, [query])
  const [app, setApp] = useState<TldrawApp | undefined>()
  /**
   * only for demo purpose,
   * will remove later
   */
  // const { borderRadius, innerPadding, pagePadding } = useControls('Grid', {
  //   innerPadding: {
  //     min: 0,
  //     max: 32,
  //     value: 8,
  //     step: 1,
  //     onEditEnd: (value: number) => window?.app?.setSlotsInnerPadding(value),
  //   },
  //   pagePadding: {
  //     min: 0,
  //     max: 32,
  //     value: 8,
  //     step: 1,
  //     onEditEnd: (value: number) => window?.app?.setPagePadding(value),
  //   },
  //   borderRadius: {
  //     min: 0,
  //     max: 32,
  //     value: 8,
  //     step: 1,
  //     onEditEnd: (value: number) => window?.app?.setSlotsBorderRadius(value),
  //   },
  // })
  // const [, setCanvas] = useControls('Canvas', () => ({
  //   zoom: {
  //     min: 1,
  //     max: 3,
  //     value: 1,
  //     step: 0.5,
  //     onEditEnd: (value: number) => console.log('zoom level', value),
  //   },
  //   size: {
  //     value: [
  //       1280 || window?.app.document[window?.app.currentPageId].size[0],
  //       640 || window?.app.document[window?.app.currentPageId].size[1],
  //     ],
  //     step: 10,
  //     onChange: (value: [number, number]) => {
  //       window?.app?.setCanvasSize(value)
  //     },
  //   },
  // }))
  // const [, set] = useControls('Text', () => ({
  //   textSize: {
  //     min: 8,
  //     max: 128,
  //     value: 28,
  //     step: 1,
  //     onEditEnd: (value: number) => {
  //       window?.app?.styleText({ size: value })
  //     },
  //   },
  //   textColor: {
  //     value: '#000',
  //     onChange: (value: string) => {
  //       window?.app?.styleText({ color: value as string })
  //     },
  //   },
  //   alignment: {
  //     options: AlignStyle,
  //     onChange: (value: AlignStyle) => {
  //       window?.app?.styleText({ alignHorizontal: value as AlignStyle })
  //     },
  //   },
  //   shadowColor: {
  //     value: '#000',
  //     onChange: (value: string) => {
  //       window?.app?.styleText({ effect: { shadowColor: value as string } })
  //     },
  //   },
  //   // shadow: {
  //   //   value: [0, 0, 0],
  //   //   step: 1,
  //   //   onChange: (value: number[]) => {
  //   //     const x = Math.floor(value[0])
  //   //     const y = Math.floor(value[1])
  //   //     const blur = Math.floor(value[2])
  //   //     if (x === 0 && y === 0 && blur === 0) {
  //   //       // reset
  //   //       window?.app?.styleText({ shadow: '' })
  //   //       return
  //   //     }
  //   //     window?.app?.styleText({ shadow: `${x}px ${y}px ${blur}px` })
  //   //   },
  //   // },
  //   // alignmentV: {
  //   //   options: VerticalAlignStyle,
  //   //   onChange: (value: VerticalAlignStyle) => {
  //   //     window?.app?.styleText({ alignVertical: value as VerticalAlignStyle })
  //   //     console.log(window?.app);
  //   //   }
  //   // },
  //   // textLineHeight: {
  //   //   min: 1,
  //   //   max: 4,
  //   //   value: 1,
  //   //   step: 0.5,
  //   //   onEditEnd: (value: number) => console.log(value),
  //   // },
  //   // preset: {
  //   //   options: textPresets.map((t) => t.name),
  //   //   onChange: (value: string) => {
  //   //     const _preset = textPresets.find((t) => t.name === value)
  //   //     if (_preset) {
  //   //       window?.app?.addTextPreset(_preset as TextPreset)
  //   //     }
  //   //   },
  //   // },
  //   // fontFamily: {
  //   //   options: [
  //   //     'Caveat Brush',
  //   //     'Source Sans Pro',
  //   //     'Crimson Pro',
  //   //     'Source Code Pro',
  //   //     'Vintage Dreams',
  //   //   ],
  //   //   onChange: (value: FontStyle) => {
  //   //     window?.app?.styleText({ font: value as FontStyle })
  //   //   },
  //   // },
  //   googleFont: {
  //     options: [
  //       'Architects Daughter',
  //       'Krona One',
  //       'Pacifico',
  //       'Gochi Hand',
  //       'Rubik Puddles',
  //       'Gaegu',
  //       'Neonderthaw',
  //       'Reenie Beanie',
  //       'Bubblegum Sans',
  //       'Tourney',
  //       'Questrial',
  //       'Nothing You Could Do',
  //       'Pangolin',
  //       'Cedarville Cursive',
  //       'Luckiest Guy',
  //     ],
  //     onChange: (value) => {
  //       applyFontStyle({
  //         fontSetting: {
  //           family: value,
  //         },
  //       })
  //       window?.app?.styleText({ font: value })
  //     },
  //   },
  // }))
  const handleChange = (app: TldrawApp, reason?: string) => {
    if (['update_page_props', 'undo', 'redo'].includes(reason)) {
      document.dispatchEvent(new Event('setPageBackground'))
    }
  }
  const handleExport = useCallback(async (app: TldrawApp, info: TDExport) => {
    // When a user exports, the default behavior is to download
    // the exported data as a file. If the onExport callback is
    // provided, it will be called instead.

    switch (ACTION) {
      case 'download': {
        // Download the file
        const blobUrl = URL.createObjectURL(info.blob)
        const link = document.createElement('a')
        link.href = blobUrl
        link.download = info.name + '.' + info.type
        link.click()
        break
      }
      case 'open': {
        // Open the file in a new tab
        const blobUrl = URL.createObjectURL(info.blob)
        const link = document.createElement('a')
        link.href = blobUrl
        link.target = '_blank'
        link.click()
        break
      }
    }
  }, [])

  useEffect(() => {
    const handleLoadFont = (e: CustomEvent) =>
      e.detail && applyFontStyle({ fontSetting: { family: e.detail } })
    // const handleSetFontData = (e: CustomEvent) => {
    //   if (e?.detail?.data) {
    //     const { textSize = 28, textColor = '#000', font, alignment, shadowColor } = e.detail.data
    //     set({ textSize, textColor, googleFont: font, alignment, shadowColor })
    //   }
    // }
    document.addEventListener('loadfont', handleLoadFont)
    // document.addEventListener('setfontdata', handleSetFontData)
    return () => {
      document.removeEventListener('loadfont', handleLoadFont)
      // document.removeEventListener('setfontdata', handleSetFontData)
    }
  }, [])

  return (
    <>
      <Leva hidden={isExportMode || !app?.settings?.isDebugMode} />
      {isExportMode ? (
        <Editor
          id="home"
          isUser={false}
          isSponsor={false}
          showUI={false}
          readOnly={false}
          onMount={setApp}
          onExport={handleExport}
        />
      ) : (
        <AppBar app={app}>
          <Editor
            id="home"
            isUser={false}
            isSponsor={false}
            showUI={false}
            readOnly={false}
            onMount={setApp}
            onChange={handleChange}
            onExport={handleExport}
          />
        </AppBar>
      )}
    </>
  )
}

export default Home

export const getServerSideProps: GetServerSideProps = async (context) => {
  const session = await getSession(context)

  return {
    props: {
      isUser: session?.user ? true : false,
      isSponsor: session?.isSponsor || false,
    },
  }
}
